/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-len */
import { Typography, Divider, Button, List, ListItem, ListItemText, Alert, Box, Checkbox } from '@mui/material';
import React, { ReactNode, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { UnpackNestedValue, useForm, SubmitHandler } from 'react-hook-form';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { start } from '../../../lib/saga-promise';
import { ColumnReopenLicense, columns3, SearchLicenseRenew, SearchLicenseRenewDefault } from './types';
import PurchaseForm from '../../../component/Layout/PurchaseForm';
import TGGrid from '../../../component/Elements/TGGrid';
import SearchResult from '../../../component/Layout/SearchResult';
import { getLicensesByUser, getRenewalDate, reopenLicensesStatus } from '../../../state/actions/license';
import OverHideText from '../../../component/Elements/OverHideText';
import { SIZE_PAGE } from '../../../constants/app';
import routes from '../../../constants/routes';
import DialogConfirmCancel from '../confirmCancel';
import DialogBankTransfer from '../DialogBankTransfer';
import StepPurchase from '../stepPurchase';
import { changeLoading } from '../../../state/actions/app';
import { getGroups } from '../../../state/actions/group';
import { getPriceLicense } from '../../../state/actions/price';

const monthDiff = (startDate: Date, endDate: Date) => {
  let months;
  if (startDate.getMonth() > endDate.getMonth() || startDate.getFullYear() > endDate.getFullYear()) {
    months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
    months -= startDate.getMonth();
    months += endDate.getMonth();
  } else {
    months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
    months += endDate.getMonth() - startDate.getMonth();
  }
  const selectedDateTime = new Date(startDate.setHours(0, 0, 0, 0));
  selectedDateTime.setMonth(selectedDateTime.getMonth() + months);
  endDate.setHours(0, 0, 0, 0);
  if (months < 13) {
    if (selectedDateTime < endDate) {
      months += 1;
    }
    return months;
  }
  return months - 1;
};

function ReOpenLicense() {
  const currentDate = new Date();
  const formatCurrentDate = currentDate.toISOString().slice(0, 10).replace(/-/g, '/');
  const [rows, setRows] = useState<any | null>();
  const getLicense = async (data: UnpackNestedValue<SearchLicenseRenew>) => {
    const dataInput = {
      ...data,
      userId: param.userId,
      purchaseType: 'Expired',
    };
    const licenses: any = await start(getLicensesByUser, dataInput, dispatch);
    setRows(licenses);
    const allRowIds = licenses.list.map((item: any) => item.licenseId);
    const filteredArray = allRowIds.filter((elementA: any) => !isNotSelectedRows.includes(elementA));
    setSelectedRows(filteredArray);
  };
  // enddate
  useEffect(() => {
    const fetchData = async () => {
      if (param.userId) {
        await start(getRenewalDate, { userId: param.userId }, dispatch);
      }
    };
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const result = useSelector((state: RootStateOrAny) => state.license);
  const { renewalDate } = result;
  const endDateReopen = new Date(renewalDate);
  const selectedEndDateReopen = `${endDateReopen.getFullYear()}/${(endDateReopen.getMonth() + 1).toString().padStart(2, '0')}/${endDateReopen
    .getDate()
    .toString()
    .padStart(2, '0')}`;

  const param = useParams();
  const { t, i18n } = useTranslation();
  const locate = i18n.language;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleSubmit, getValues, setValue } = useForm<SearchLicenseRenew>({
    defaultValues: SearchLicenseRenewDefault,
  });
  const pageChange = (_: any, page: number) => {
    setValue('pageIndex', page);
    handleSubmit(getLicense)();
  };

  useEffect(() => {
    handleSubmit(getLicense)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isNotSelectedRows, setIsNotSelectedRows] = useState<string[]>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [totalAmount, setTotalAmount] = useState('0');
  const handleRowCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, rowId: string) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, rowId]);
      setIsNotSelectedRows(isNotSelectedRows.filter((id) => id !== rowId));
    } else {
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
      setIsNotSelectedRows([...isNotSelectedRows, rowId]);
    }
  };

  const [dataPrice, setDataPrice] = useState({
    price: 0,
    priceLicenseMonth: '',
    availableDeviceNumber: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res : any = await start(getPriceLicense, null, dispatch);
        if (res instanceof Object) {
          setDataPrice(res);
        }
      } catch (error) {
        // eslint-disable-next-line no-empty
      }
    };
    fetchData();
  }, [dispatch]);

  const priceLicenseMonthCompanyArray = dataPrice.priceLicenseMonth
    .split(',')
    .map((item) => parseInt(item, 10));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [monthsOfUse, setMonthsOfUse] = useState(12);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [unitAmount, setUnitAmount] = useState(0);
  useEffect(() => {
    if (rows !== undefined) {
      const numberMonth = monthDiff(currentDate, endDateReopen);
      setMonthsOfUse(numberMonth);
      setUnitAmount(priceLicenseMonthCompanyArray[numberMonth - 1]);
      const calculatedMoney = (rows.count - isNotSelectedRows.length) * priceLicenseMonthCompanyArray[numberMonth - 1];
      setTotalAmount(calculatedMoney.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows, currentDate, endDateReopen]);


  const [codeError, setCodeError] = useState<string>('');
  const [open, setOpen] = useState(false);
  const handleOpenDialog = () => {
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleConfirmation = (confirm: boolean) => {
    if (confirm) {
      navigate(routes.purchase);
    }
  };

  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    if (selectedRows.length > 0) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [selectedRows]);

  const onSubmit: SubmitHandler<any> = async () => {
    try {
      if (param.userId) {
        endDateReopen.setHours(23);
        endDateReopen.setMinutes(59);
        const extensionLicenses = {
          userId: param.userId,
          listLicense: selectedRows,
          listLicenseNotBuy: isNotSelectedRows,
          endDateReopen,
          Locate: locate,
          unitAmount
        };
        const urlPayment: any = await start(reopenLicensesStatus, extensionLicenses, dispatch);
        localStorage.setItem('userId', param.userId);
        setCodeError('');
        dispatch(changeLoading(true));
        setTimeout(() => {
          window.location.href = urlPayment;
        }, 2000);
      }
    } catch (error: any) {
      // eslint-disable-next-line no-empty
    }
  };

  const onSubmitBankTransfer: SubmitHandler<any> = async () => {
    endDateReopen.setHours(23);
    endDateReopen.setMinutes(59);
    try {
      const extensionLicenses = {
        userId: param.userId,
        listLicense: selectedRows,
        listLicenseNotBuy: isNotSelectedRows,
        ModeSubscription: false,
        endDateReopen,
        Locate: locate,
        unitAmount
      };
      const urlPayment: any = await start(reopenLicensesStatus, extensionLicenses, dispatch);
      setCodeError('');
      dispatch(changeLoading(true));
      setTimeout(() => {
        window.location.href = urlPayment;
      }, 2000);
    } catch (error: any) {
      // eslint-disable-next-line no-empty
    }
  };

  const groups = useSelector((state: RootStateOrAny) => state.group.allGroups);
  useEffect(() => {
    start(getGroups, { noLoading: true }, dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [openPaymentMethod, setOpenPaymentMethod] = useState(false);
  // mouse event in dialog
  const [showErrors, setShowErrors] = useState(true);
  const handleOpenDialogPaymentMethod = async () => {
    const { isAgent } = groups[0];
    if (rows.count > 0) {
      if (isAgent === true) {
        handleSubmit(onSubmit)();
      } else {
        setOpenPaymentMethod(true);
      }
    } else {
      // eslint-disable-next-line no-alert
      alert('ライセンスがありません');
    }
  };
  const handleCloseDialogPaymentMethod = () => {
    setOpenPaymentMethod(false);
  };
  const handleConfirmationPaymentMethod = async (confirm: boolean) => {
    setShowErrors(true);
    if (confirm) {
      handleSubmit(onSubmit)();
    } else {
      handleSubmit(onSubmitBankTransfer)();
    }
  };

  // event change language, turn off errors message
  useEffect(() => {
    const handleChangeLanguage = () => {
      setShowErrors(false);
    };

    i18n.on('languageChanged', handleChangeLanguage);
    return () => {
      i18n.off('languageChanged', handleChangeLanguage);
    };
  }, [i18n]);

  // show errors when click button submit
  const tabs = [
    {
      title: t('licenseUser.button.restart.license'),
    },
    {
      title: t('purchaseStepInputInformationPay'),
    },
    {
      title: t('purchaseStepConfirmLast'),
    },
    {
      title: t('purchaseStepPurchaseComplete'),
    },
  ];

  let reopenLicenseDescription = t('purchaseDetailsDescription1');
  reopenLicenseDescription = reopenLicenseDescription.replace('{price_license}', dataPrice.price.toLocaleString());

  let reopenLicenseDescription5 = t('purchaseDetailsDescription3');
  reopenLicenseDescription5 = reopenLicenseDescription5.replace('{number_device}', dataPrice.availableDeviceNumber.toString());

  return (
    <div className="purchase__container">
      <PurchaseForm>
        <StepPurchase tabs={tabs} idx={0} />
        <TGGrid container columns={12} mt={8}>
          <TGGrid item sm={12} mb={1}>
            {showErrors && codeError && (
              <Alert severity="error" icon={false}>
                {codeError}
              </Alert>
            )}
          </TGGrid>
          <TGGrid item xs={12}>
            <Typography variant="h3">{t('reopenLicenses')}</Typography>
          </TGGrid>
          <TGGrid item xs={12} mt={1}>
            <Typography variant="body1">{t('reopenLicenses.description')}</Typography>
          </TGGrid>
          <TGGrid item xs={12} mt={6} mb={1}>
            <Typography variant="h3">{t('usageStartDate.expirationDate')}</Typography>
          </TGGrid>
        </TGGrid>
        <Divider sx={{ borderBottom: '2px solid' }} />
        <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={2} mt={2}>
          <TGGrid item md={2} xs={3}>
            <Typography variant="body1" className="fs-09-rem">
              {t('purchaseDateStartLicense')}
            </Typography>
          </TGGrid>
          <TGGrid item md={5} xs={8}>
            <Typography variant="body1" className="fs-09-rem">
              {formatCurrentDate}
            </Typography>
            <List>
              <ListItem sx={{ padding: '0' }}>
                <ListItemText primary={t('reopenStartDateDescription')} />
              </ListItem>
            </List>
          </TGGrid>
        </TGGrid>
        <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={2} mt={2}>
          <TGGrid item md={2} xs={3}>
            <Typography variant="body1" className="fs-09-rem">
              {t('purchaseDateEndLicense')}
            </Typography>
          </TGGrid>
          <TGGrid item md={10} xs={9}>
            <Typography variant="body1" className="fs-09-rem">
              {selectedEndDateReopen}
            </Typography>
            <List>
              <ListItem sx={{ padding: '0' }}>
                <ListItemText primary={t('reopenEndDateDescription1')} />
              </ListItem>
              <ListItem sx={{ padding: '0' }}>
                <ListItemText primary={t('reopenEndDateDescription2')} />
              </ListItem>
            </List>
          </TGGrid>
        </TGGrid>
        <TGGrid item xs={12} mt={6} mb={1}>
          <Typography variant="h3">{t('expiredLicenseInformation')}</Typography>
        </TGGrid>
        <Divider sx={{ borderBottom: '2px solid' }} />
        <TGGrid>
          <SearchResult<ColumnReopenLicense>
            totalCount={rows?.count ?? 0}
            page={getValues('pageIndex')}
            columns={columns3}
            rows={rows?.list ?? []}
            handlePageChange={pageChange}
            tableCell={(row: any, rowKey: number, column2: ColumnReopenLicense): ReactNode => {
              const value = row[column2.id];
              switch (column2.id) {
                case 'licenseId':
                  return rowKey + 1 + (getValues('pageIndex') - 1) * SIZE_PAGE;
                case 'UserNameWithLoginId':
                  if (row.userName !== null) {
                    return `${row.userName}`;
                  }

                  return '';

                case 'availableDeviceNumber':
                  return `${row.availableDeviceNumber ?? 0}`;
                case 'isReopen':
                  return (
                    <Checkbox
                      checked={selectedRows.includes(row.licenseId)}
                      onChange={(event) => handleRowCheckboxChange(event, row.licenseId)}
                      color="primary"
                    />
                  );
                default:
                  return column2.format ? column2.format(value) : <OverHideText>{value}</OverHideText>;
              }
            }}
          />
        </TGGrid>
        <TGGrid container direction="row" justifyContent="start" alignItems="center" mb={1}>
          <TGGrid item xs={12}>
            <List>
              <ListItem sx={{ padding: '0' }}>
                <ListItemText primary={t('reopenLicenseDescription1')} />
              </ListItem>
              <ListItem sx={{ padding: '0' }}>
                <ListItemText primary={reopenLicenseDescription} />
              </ListItem>
              <ListItem sx={{ padding: '0' }}>
                <ListItemText primary={t('reopenLicenseDescription3')} />
              </ListItem>
              <ListItem sx={{ padding: '0' }}>
                <ListItemText primary={t('reopenLicenseDescription4')} />
              </ListItem>
              <ListItem sx={{ padding: '0' }}>
                <ListItemText primary={reopenLicenseDescription5} />
              </ListItem>
              <ListItem sx={{ padding: '0' }}>
                <ListItemText primary={t('reopenLicenseDescription6')} />
              </ListItem>
            </List>
          </TGGrid>
        </TGGrid>
        <Box display="flex" alignItems="center" justifyContent="start" my={1}>
          <Typography variant="body1" className="fs-09-rem">
            {t('purchasePaymentAmount')}
          </Typography>
          <Typography variant="body1" className="fs-09-rem" ml={6}>
            {totalAmount}
            &nbsp;
            {t('yenUnit')}
          </Typography>
        </Box>
        <TGGrid container mt={10} mb={3} display="flex" justifyContent="right">
          <TGGrid item md={8} sm={11}>
            <Box display="flex" alignItems="center" justifyContent="right">
              <Button
                variant="contained"
                sx={{ marginLeft: '10%', width: '120px', fontSize: '14px' }}
                className="purchase-button-gray"
                onClick={handleOpenDialog}
              >
                {t('purchaseEmail.button.cancel')}
              </Button>
              <Button
                variant="contained"
                sx={{ marginLeft: '20px', width: 'auto', fontSize: '14px' }}
                onClick={handleOpenDialogPaymentMethod}
                disabled={!isChecked}
              >
                {t('reopenLicenseBtn')}
              </Button>
            </Box>
          </TGGrid>
        </TGGrid>
        <DialogConfirmCancel open={open} onClose={handleCloseDialog} onConfirm={handleConfirmation} />
        <DialogBankTransfer
          open={openPaymentMethod}
          onClose={handleCloseDialogPaymentMethod}
          onSubscription={handleConfirmationPaymentMethod}
        />
      </PurchaseForm>
    </div>
  );
}

export default ReOpenLicense;
