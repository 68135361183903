import { Typography, Button, Divider } from '@mui/material';
import { UnpackNestedValue, useForm } from 'react-hook-form';
import { ReactNode, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { PDFExport } from '@progress/kendo-react-pdf';
import { start } from '../../lib/saga-promise';
import { InputInfoBuyerSuccess } from '../../state/actions/purchase';
import { getLicensesByTemporaryIdSuccess } from '../../state/actions/license';
import SearchResult from '../../component/Layout/SearchResult';
import { SIZE_PAGE } from '../../constants/app';
import {
  ColumnBuyLicenseNotify,
  columns,
  SearchLicenseRenew,
  SearchLicenseRenewDefault,
} from './buy_license/typesNotify';
import TGGrid from '../../component/Elements/TGGrid';
import PurchaseForm from '../../component/Layout/PurchaseForm';
import { userDetail } from '../../state/actions/user';
import SearchResultPrint from '../../component/Layout/SearchResultPrint';



function AddBuyerNotify() {
  // ================================================ LOCAL STATE AREA ========================================= //
  const { t } = useTranslation();
  const param = useParams();
  const dispatch = useDispatch();
  // ================================================ END LOCAL STATE AREA ========================================= //
  // submit form
  const { handleSubmit, getValues, setValue } = useForm<SearchLicenseRenew>({
    defaultValues: SearchLicenseRenewDefault,
  });
  const buyerDetail = useSelector((state: RootStateOrAny) => state.purchase.buyer);
  // get License Paid
  const [flagShowLayout, setFlagShowLayout] = useState(false);
  const getLicense = async (data: UnpackNestedValue<SearchLicenseRenew>) => {
    const dataInput = {
      ...data,
      temporaryId: param.id,
    };
    await start(getLicensesByTemporaryIdSuccess, dataInput, dispatch);
    setFlagShowLayout(true);
  };
  useEffect(() => {
    start(InputInfoBuyerSuccess, { InformationPurchaserTemporaryId: param.id }, dispatch);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, buyerDetail]);

  const [temRes, settemRes] = useState({
    bankCode: '',
    bankName: '',
    branchCode: '',
    branchName: '',
    accountType: '',
    accountNumber: '',
    accountHolderName: ''
  });
  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    const fetchData = async () => {
      if (param.id) {
        const res: any = await start(InputInfoBuyerSuccess, { InformationPurchaserTemporaryId: param.id }, dispatch);
        settemRes(res);
        if (res.infomationPurchaseId) {
          clearInterval(intervalId);
          setFlagShowLayout(true);
          handleSubmit(getLicense)();
        } else {
          clearInterval(intervalId);
          intervalId = setInterval(fetchData, 2000);
        }
      }
    };
    fetchData();
    return () => {
      clearInterval(intervalId);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, param.id]);
  const rows = useSelector((state: RootStateOrAny) => state.license);

  // Page change
  const pageChange = (_: any, page: number) => {
    setValue('pageIndex', page);
    handleSubmit(getLicense)();
  };

  const [extensionLicenses, setextensionLicenses] = useState({
    totalAmount: '',
  });
  const removeLocalStorage = () => {
    localStorage.removeItem('updateBankLicenses');
  };

  useEffect(() => {
    if (localStorage.getItem('extensionLicenses') != null) {
      const storedObjectString = localStorage.getItem('extensionLicenses');
      if (storedObjectString !== null) {
        setextensionLicenses(JSON.parse(storedObjectString));
        start(userDetail, { userId: JSON.parse(storedObjectString).userId }, dispatch);
      }
    } else if (localStorage.getItem('updateBankLicenses') != null) {
      const storedObjectString = localStorage.getItem('updateBankLicenses');
      if (storedObjectString !== null) {
        setextensionLicenses(JSON.parse(storedObjectString));
        start(userDetail, { userId: JSON.parse(storedObjectString).userId }, dispatch);
        removeLocalStorage();
      }
    }
    localStorage.removeItem('extensionLicenses');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [layoutAllLicense, setlayoutAllLicense] = useState(false);
  const pdfExportComponent = useRef<PDFExport>(null);

  const handlePrintPdf = async () => {
    const input = document.getElementById('divToPrint');
    const btnPrint = document.getElementById('btn_print');
    if (btnPrint) {
      btnPrint.style.display = 'none';
      setlayoutAllLicense(true);
    }
    if (input && pdfExportComponent.current) {
      await new Promise((resolve) => {
        pdfExportComponent.current?.save(() => {
          resolve(true);
        });
      });

      if (btnPrint) {
        btnPrint.style.display = 'block';
        setlayoutAllLicense(false);
      }
    }
  };
  const renderAccountType = (accountType: string) => {
    switch (accountType) {
      case 'futsu':
        return '普通';
      case 'toza':
        return '当座';
      default:
        return null;
    }
  };
  return (
    <div className="purchase__container">
      <PDFExport
        ref={pdfExportComponent}
        paperSize="A4"
        scale={0.5}
        margin={40}
        fileName="tgd_start.pdf"
        author="KendoReact Team"
      >
        <PurchaseForm className="purchase__registerNotify" classId="divToPrint">
          <TGGrid
            container
            columns={12}
            mt={1}
            direction="row"
            justifyContent="start"
            alignItems="center"
            className="fs-09-rem font-NotoSansJP"
          >
            <TGGrid item xs={3} display="flex" alignItems="center">
              <Typography variant="h3" className="font-NotoSansJP">{t('NotifyBuyerInformation.success.label.top')}</Typography>
            </TGGrid>
            <TGGrid item xs={9} display="flex" justifyContent="end">
              <Button
                variant="contained"
                sx={{ width: '120px', fontSize: '14px' }}
                id="btn_print"
                onClick={handlePrintPdf}
                className="font-NotoSansJP"
              >
                {t('purchaseBuyerInformation.success.button.print')}
              </Button>
            </TGGrid>
          </TGGrid>
          <Typography variant="h5" mt={2} mb={1} color="inherit" className="fs-09-rem font-NotoSansJP">
            {t('NotifyBuyerInformation.success.label.description1')}
          </Typography>
          <Typography variant="h5" mb={1} color="inherit" className="fs-09-rem font-NotoSansJP">
            {t('NotifyBuyerInformation.success.label.description2')}
          </Typography>
          {temRes.bankCode !== '' && (
            <>
              <Typography variant="h4" mt={3} mb={1} className="font-NotoSansJP">
                {t('TransferInformation')}
              </Typography>
              <Divider sx={{ borderBottom: '2px solid' }} />
              <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={2} mt={1}>
                <TGGrid item md={2} xs={3}>
                  <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{t('BankCode')}</Typography>
                </TGGrid>
                <TGGrid item md={5} xs={8}>
                  <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{temRes.bankCode}</Typography>
                </TGGrid>
                <TGGrid item md={5} xs={1}>{ }</TGGrid>
                <TGGrid item md={2} xs={3}>
                  <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{t('BankName')}</Typography>
                </TGGrid>
                <TGGrid item md={5} xs={8}>
                  <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{temRes.bankName}</Typography>
                </TGGrid>
                <TGGrid item md={5} xs={1}>{ }</TGGrid>
                <TGGrid item md={2} xs={3}>
                  <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{t('BranchCode')}</Typography>
                </TGGrid>
                <TGGrid item md={5} xs={8}>
                  <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{temRes.branchCode}</Typography>
                </TGGrid>
                <TGGrid item md={5} xs={1}>{ }</TGGrid>
                <TGGrid item md={2} xs={3}>
                  <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{t('BranchName')}</Typography>
                </TGGrid>
                <TGGrid item md={5} xs={8}>
                  <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{temRes.branchName}</Typography>
                </TGGrid>
                <TGGrid item md={5} xs={1}>{ }</TGGrid>
                <TGGrid item md={2} xs={3}>
                  <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{t('AccountType')}</Typography>
                </TGGrid>
                <TGGrid item md={5} xs={8}>
                  <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{renderAccountType(temRes.accountType)}</Typography>
                </TGGrid>
                <TGGrid item md={5} xs={1}>{ }</TGGrid>
                <TGGrid item md={2} xs={3}>
                  <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{t('AccountNumber')}</Typography>
                </TGGrid>
                <TGGrid item md={5} xs={8}>
                  <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{temRes.accountNumber}</Typography>
                </TGGrid>
                <TGGrid item md={5} xs={1}>{ }</TGGrid>
                <TGGrid item md={2} xs={3}>
                  <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{t('AccountHolder')}</Typography>
                </TGGrid>
                <TGGrid item md={5} xs={8}>
                  <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{temRes.accountHolderName}</Typography>
                </TGGrid>
                <TGGrid item md={5} xs={1}>{ }</TGGrid>
                <TGGrid item md={2} xs={3}>
                  <Typography variant="body1" className="fs-09-rem font-NotoSansJP">{t('TotalAmount')}</Typography>
                </TGGrid>
                <TGGrid item md={5} xs={8}>
                  <Typography variant="body1" className="fs-09-rem font-NotoSansJP">
                    {extensionLicenses.totalAmount}
                    {' '}
  &nbsp;
                    {t('YenTaxIncluded')}
                  </Typography>
                </TGGrid>
              </TGGrid>
            </>
          )}
          <Typography variant="h4" mt={3} mb={1} className="fs-09-rem font-NotoSansJP">
            {t('purchaseBuyerInformation.success.label.licenseInfo')}
          </Typography>
          <Divider sx={{ borderBottom: '2px solid' }} />
          {layoutAllLicense === false ? (
            <TGGrid className="font-NotoSansJP font-pdf">
              {flagShowLayout && (
              <SearchResult<ColumnBuyLicenseNotify>
                totalCount={rows?.count}
                page={getValues('pageIndex')}
                columns={columns}
                rows={rows?.licensesByUser}
                handlePageChange={pageChange}
                tableCell={(row: any, rowKey: number, column: ColumnBuyLicenseNotify): ReactNode => {
                  const value = row[column.id];
                  switch (column.id) {
                    case 'licenseId':
                      return rowKey + 1 + (getValues('pageIndex') - 1) * SIZE_PAGE;
                    case 'startDate':
                      return moment(row.startDate).format('YYYY/MM/DD');
                    case 'endDate':
                      return moment(row.endDate).format('YYYY/MM/DD');
                    case 'UserNameWithLoginId':
                      return row.userName;
                    default:
                      return value;
                  }
                }}
              />
              )}
            </TGGrid>
          ) : (
            <TGGrid className="font-NotoSansJP font-pdf">
              {flagShowLayout && (
              <SearchResultPrint<ColumnBuyLicenseNotify>
                totalCount={rows?.count}
                columns={columns}
                rows={rows?.listAlllicensesByUser}
                tableCell={(row: any, rowKey: number, column: ColumnBuyLicenseNotify): ReactNode => {
                  const value = row[column.id];
                  switch (column.id) {
                    case 'licenseId':
                      return rowKey + 1;
                    case 'startDate':
                      return moment(row.startDate).format('YYYY/MM/DD');
                    case 'endDate':
                      return moment(row.endDate).format('YYYY/MM/DD');
                    case 'UserNameWithLoginId':
                      return row.userName;
                    default:
                      return value;
                  }
                }}
              />
              )}
            </TGGrid>
          )}
        </PurchaseForm>
      </PDFExport>
    </div>
  );
}

export default AddBuyerNotify;
