/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-len */
import { Typography, Divider, Button, List, ListItem, ListItemText, Box, Checkbox } from '@mui/material';
import { useState, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import moment from 'moment';
import { start } from '../../../lib/saga-promise';
import { SearchLicenseRenew, SearchLicenseRenewDefault } from './types';
import PurchaseForm from '../../../component/Layout/PurchaseForm';
import TGGrid from '../../../component/Elements/TGGrid';
import StepPurchase from '../stepPurchase';
import { userDetail } from '../../../state/actions/user';
import DialogBankTransfer from '../DialogBankTransfer';
import { buyAdditionalLicenses } from '../../../state/actions/purchase';
import { getGroups } from '../../../state/actions/group';
import routes from '../../../constants/routes';

function AddLicenseConfirm() {
  const param = useParams();
  const { t, i18n } = useTranslation();
  const locate = i18n.language;
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const { handleSubmit } = useForm<SearchLicenseRenew>({
    defaultValues: SearchLicenseRenewDefault,
  });
  const currentDate = new Date();
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [codeError, setCodeError] = useState<string>('');
  const [extensionLicenses, setextensionLicenses] = useState({
    infoForm: '',
    userId: '',
    totalAmount: '',
    AmountOfLicenseTrial: '',
    amountOfLicense: '',
    unitAmount: '',
    endDate: '',
    startDate: '',
    monthsOfUse: '',
    price: '',
  });
  useEffect(() => {
    if (localStorage.getItem('extensionLicenses') != null) {
      const storedObjectString = localStorage.getItem('extensionLicenses');
      if (storedObjectString !== null) {
        setextensionLicenses(JSON.parse(storedObjectString));
        start(userDetail, { userId: JSON.parse(storedObjectString).userId }, dispatch);
      }
    } else {
      window.location.href = '/purchase';
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const detailUser = useSelector((state: RootStateOrAny) => state.user.user);

  const handleReturn: SubmitHandler<any> = async () => {
    window.location.href = `/purchase/buyAdditionalLicenses/${localStorage.getItem('userId')}`;
  };
  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      // removeLocalStorage();
      currentDate.setHours(23);
      currentDate.setMinutes(59);
      const dataInput = {
        ...data,
        AmountOfLicenseTrial: extensionLicenses.AmountOfLicenseTrial,
        AmountOfLicense: extensionLicenses.amountOfLicense,
        totalAmount: extensionLicenses.totalAmount,
        unitAmount: extensionLicenses.unitAmount,
        endDate: extensionLicenses.endDate,
        startDate: extensionLicenses.startDate,
        userId: param.userId,
        ModeSubscription: true,
        Locate: locate,
      };
      const res: any = await start(buyAdditionalLicenses, dataInput, dispatch);
      if (res.sessionUrl) {
        window.location.href = res.sessionUrl;
      } else {
        navigate(routes.AddBuyerNotify.replace(':id', res.temporaryId));
      }
    } catch (error: any) {
      setCodeError(t('purchaseEmail.systemError'));
      window.scrollTo(0, 0);
    }
  };

  const onSubmitBankTransfer: SubmitHandler<any> = async (data) => {
    try {
      currentDate.setHours(23);
      currentDate.setMinutes(59);
      const dataInput = {
        ...data,
        AmountOfLicenseTrial: extensionLicenses.AmountOfLicenseTrial,
        AmountOfLicense: extensionLicenses.amountOfLicense,
        totalAmount: extensionLicenses.totalAmount,
        unitAmount: extensionLicenses.unitAmount,
        startDate: extensionLicenses.startDate,
        endDate: extensionLicenses.endDate,
        userId: param.userId,
        ModeSubscription: false,
        Locate: locate,
      };
      const res: any = await start(buyAdditionalLicenses, dataInput, dispatch);
      if (res.sessionUrl) {
        window.location.href = res.sessionUrl;
      }
    } catch (error: any) {
      // eslint-disable-next-line no-empty
    }
  };

  const groups = useSelector((state: RootStateOrAny) => state.group.allGroups);
  useEffect(() => {
    start(getGroups, { noLoading: true }, dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [open, setOpen] = useState(false);
  // mouse event in dialog
  const handleOpenDialog = () => {
    const { isAgent } = groups[0];
    if (extensionLicenses.infoForm === 'Trial' || isAgent === true) {
      handleSubmit(onSubmit)();
    } else {
      setOpen(true);
    }
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleConfirmation = (confirm: boolean) => {
    setShowErrors(true);
    if (confirm) {
      handleSubmit(onSubmit)();
    } else {
      handleSubmit(onSubmitBankTransfer)();
    }
  };

  // event change language, turn off errors message
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showErrors, setShowErrors] = useState(true);
  useEffect(() => {
    const handleChangeLanguage = () => {
      setShowErrors(false);
    };

    i18n.on('languageChanged', handleChangeLanguage);
    return () => {
      i18n.off('languageChanged', handleChangeLanguage);
    };
  }, [i18n]);
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const handleTextClick = () => {
    setIsChecked(!isChecked);
  };


  const tabs = [
    {
      title: t('purchaseStepBuyLicense'),
    },
    {
      title: t('purchaseStepConfirmLast'),
    },
    {
      title: t('purchaseStepInputInformationPay'),
    },
    {
      title: t('purchaseStepPurchaseComplete'),
    },
  ];
  return (
    <div className="purchase__container">
      <PurchaseForm>
        <StepPurchase tabs={tabs} idx={1} />
        <TGGrid container columns={12} mt={8}>
          <TGGrid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h3">{t('purchase.title.confirmInfo')}</Typography>
          </TGGrid>
          <TGGrid item xs={12} mt={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="body1">{t('purchase.notes.confirmInfo.description')}</Typography>
          </TGGrid>
        </TGGrid>
        <TGGrid>
          {detailUser.roleId === 2 && (
            <>
              <TGGrid item xs={12} mt={3} mb={1}>
                <Typography variant="h4">{t('purchaseUserInformation2')}</Typography>
              </TGGrid>
              <Divider sx={{ borderBottom: '2px solid' }} />
              <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
                <TGGrid item md={3} xs={4}>
                  <Typography variant="body1" className="fs-09-rem">
                    {t('purchaseCompanyName')}
                  </Typography>
                </TGGrid>
                <TGGrid item md={3} xs={4}>
                  <Typography variant="body1" className="fs-09-rem">
                    {detailUser.groupName}
                  </Typography>
                </TGGrid>
              </TGGrid>
            </>
          )}
          <TGGrid item xs={12} mt={3} mb={1}>
            <Typography variant="h4">
              {detailUser.roleId === 2 ? t('purchaseBuyerInformation.success.label.corporateAdminInfo') : t('purchaseUserInformation3')}
            </Typography>
          </TGGrid>
          <Divider sx={{ borderBottom: '2px solid' }} />
          <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
            <TGGrid item md={3} xs={4}>
              <Typography variant="body1" className="fs-09-rem">
                {t('purchaseBuyerInformation.success.label.fullName')}
              </Typography>
            </TGGrid>
            <TGGrid item md={3} xs={4}>
              <Typography variant="body1" className="fs-09-rem">
                {detailUser.username}
              </Typography>
            </TGGrid>
          </TGGrid>
          <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
            <TGGrid item md={3} xs={4}>
              <Typography variant="body1" className="fs-09-rem">
                {t('common.label.email')}
              </Typography>
            </TGGrid>
            <TGGrid item md={5} xs={8}>
              <Typography
                variant="body1"
                component="a"
                href={`mailto:${detailUser.email}`}
                className="fs-09-rem"
              >
                {detailUser.email}
              </Typography>
            </TGGrid>
          </TGGrid>
          <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
            <TGGrid item md={3} xs={4}>
              <Typography variant="body1" className="fs-09-rem">
                {t('common.label.postcode')}
              </Typography>
            </TGGrid>
            <TGGrid item md={3} xs={4}>
              <Typography variant="body1" className="fs-09-rem">
                {detailUser.roleId !== 2 ? detailUser.postcode : detailUser.postcodeGroup}
              </Typography>
            </TGGrid>
          </TGGrid>
          <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
            <TGGrid item md={3} xs={4}>
              <Typography variant="body1" className="fs-09-rem">
                {t('common.label.address')}
              </Typography>
            </TGGrid>
            <TGGrid item md={3} xs={4}>
              <Typography variant="body1" className="fs-09-rem">
                {detailUser.roleId !== 2 ? detailUser.address : detailUser.addressGroup}
              </Typography>
            </TGGrid>
          </TGGrid>
          <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
            <TGGrid item md={3} xs={4}>
              <Typography variant="body1" className="fs-09-rem">
                {t('common.label.phoneNumber')}
              </Typography>
            </TGGrid>
            <TGGrid item md={3} xs={4}>
              <Typography variant="body1" className="fs-09-rem">
                {detailUser.roleId !== 2 ? detailUser.phoneNumber : detailUser.phoneNumberGroup}
              </Typography>
            </TGGrid>
          </TGGrid>
        </TGGrid>
        <TGGrid item xs={12} mt={3} mb={1}>
          <Typography variant="h4">{t('registerContent')}</Typography>
        </TGGrid>
        <Divider sx={{ borderBottom: '2px solid' }} />
        <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
          <TGGrid item md={3} xs={4}>
            <Typography variant="body1" className="fs-09-rem">
              {t('registerDate')}
            </Typography>
          </TGGrid>
          <TGGrid item md={3} xs={4}>
            <Typography variant="body1" className="fs-09-rem">
              {moment(currentDate).format('YYYY/MM/DD')}
            </Typography>
          </TGGrid>
        </TGGrid>
        <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
          <TGGrid item md={3} xs={4}>
            <Typography variant="body1" className="fs-09-rem">
              {t('purchaseBuyerInformation.success.label.startDate')}
            </Typography>
          </TGGrid>
          <TGGrid item md={3} xs={4}>
            <Typography variant="body1" className="fs-09-rem">
              {moment(extensionLicenses.startDate).format('YYYY/MM/DD')}
            </Typography>
          </TGGrid>
        </TGGrid>
        {extensionLicenses.infoForm !== 'Trial' && (
          <>
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('common.label.endDate')}
                </Typography>
              </TGGrid>
              <TGGrid item md={9} xs={8} mt={3}>
                <Typography variant="body1" className="fs-09-rem">
                  {moment(extensionLicenses.endDate).format('YYYY/MM/DD')}
                </Typography>
                <List>
                  <ListItem sx={{ padding: '0' }}>
                    <ListItemText primary={t('confirmEnddateTrialEx1')} />
                  </ListItem>
                  <ListItem sx={{ padding: '0' }}>
                    <ListItemText primary={t('confirmEnddateTrialEx2')} />
                  </ListItem>
                </List>
              </TGGrid>
            </TGGrid>
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('deliveryTime')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={1}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('deliveryTimeEx')}
                </Typography>
              </TGGrid>
            </TGGrid>
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('additionForSalesPrice')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('additionForSalesPriceEx')}
                </Typography>
              </TGGrid>
            </TGGrid>
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('licensesBuy')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {extensionLicenses.amountOfLicense + extensionLicenses.AmountOfLicenseTrial}
                </Typography>
              </TGGrid>
            </TGGrid>
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('LicenseUnitPrice')}
                </Typography>
              </TGGrid>
              <TGGrid item md={9} xs={8}>
                <Typography variant="body1" className="fs-09-rem">
                  {extensionLicenses.price}
                  &nbsp;
                  {t('YenTaxIncluded')}
                </Typography>
                <List>
                  <ListItem sx={{ padding: '0' }}>
                    <ListItemText primary={t('LicenseUnitPriceTrialEx2')} />
                  </ListItem>
                  <ListItem sx={{ padding: '0' }}>
                    <ListItemText primary={t('LicenseUnitPriceEx')} />
                  </ListItem>
                </List>
              </TGGrid>
            </TGGrid>
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('MonthsOfUse')}
                </Typography>
              </TGGrid>
              <TGGrid item md={9} xs={8}>
                <Typography variant="body1" className="fs-09-rem">
                  {`${extensionLicenses.monthsOfUse} ${t('months')}`}
                </Typography>
              </TGGrid>
            </TGGrid>
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('TotalAmount')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {extensionLicenses.totalAmount}
                  {' '}
&nbsp;
                  {t('YenTaxIncluded')}
                </Typography>
              </TGGrid>
            </TGGrid>
            <TGGrid item xs={12} mt={3} mb={1}>
              <Typography variant="h4">{t('PaymentMethods')}</Typography>
            </TGGrid>
            <Divider sx={{ borderBottom: '2px solid' }} />
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('CreditCardCompany')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('CreditCardCompanyEx')}
                </Typography>
              </TGGrid>
            </TGGrid>
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('BankTransferDetails')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('BankTransferDetailsEx')}
                </Typography>
              </TGGrid>
            </TGGrid>
            <TGGrid item xs={12} mt={3} mb={1}>
              <Typography variant="h4">{t('PaymentDeadline')}</Typography>
            </TGGrid>
            <Divider sx={{ borderBottom: '2px solid' }} />
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('CreditCardPayment')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('CreditCardPaymentEx')}
                </Typography>
              </TGGrid>
            </TGGrid>
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('BankPayment')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('BankPaymentEx')}
                </Typography>
              </TGGrid>
            </TGGrid>
            <TGGrid item xs={12} mt={3} mb={1}>
              <Typography variant="h4">{t('RegardingCancellationTermination')}</Typography>
            </TGGrid>
            <Divider sx={{ borderBottom: '2px solid' }} />
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('ProductExchangeReturn')}
                </Typography>
              </TGGrid>
              <TGGrid item md={9} xs={8}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('ProductExchangeReturnEx')}
                </Typography>
              </TGGrid>
            </TGGrid>
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('Cancellation')}
                </Typography>
              </TGGrid>
              <TGGrid item md={9} xs={8}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('CancellationEx')}
                </Typography>
              </TGGrid>
            </TGGrid>
            <TGGrid item xs={12} mt={3} mb={1}>
              <Typography variant="h4">{t('RegardingContractExtension')}</Typography>
            </TGGrid>
            <Divider sx={{ borderBottom: '2px solid' }} />
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('ContractExtension')}
                </Typography>
              </TGGrid>
              <TGGrid item md={9} xs={8}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('ContractExtensionEx1')}
                </Typography>
                <Typography variant="body1" className="fs-09-rem">
                  {t('ContractExtensionEx2')}
                </Typography>
              </TGGrid>
            </TGGrid>
            <TGGrid item xs={12} mt={3} mb={1}>
              <Typography variant="h4">{t('userImport.label.csvPName')}</Typography>
            </TGGrid>
            <Divider sx={{ borderBottom: '2px solid' }} />
          </>
        )}
        <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
          <TGGrid item md={3} xs={4}>
            {extensionLicenses.infoForm !== 'Trial' && (
              <Typography variant="body1" className="fs-09-rem">
                {t('userImport.label.csvPName')}
              </Typography>
            )}
          </TGGrid>
          <TGGrid item md={9} xs={8}>
            <Typography mt={4} display="flex" alignItems="center" onClick={handleTextClick}>
              <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
              {t('noteContractEx')}
            </Typography>
          </TGGrid>
        </TGGrid>
        <TGGrid container mt={5} mb={3} display="flex" justifyContent="right">
          <TGGrid item md={8} xs={11}>
            <Box display="flex" alignItems="center" justifyContent="right">
              <Button
                variant="contained"
                sx={{ marginLeft: '10%', width: '120px', fontSize: '14px' }}
                className="purchase-button-gray"
                onClick={handleReturn}
              >
                {t('returnButton')}
              </Button>
              <Button
                variant="contained"
                disabled={!isChecked}
                sx={{ marginLeft: '20px', width: '120px', fontSize: '14px' }}
                onClick={handleOpenDialog}
              >
                {t('purchase.button.next')}
              </Button>
            </Box>
          </TGGrid>
        </TGGrid>
      </PurchaseForm>
      <DialogBankTransfer open={open} onClose={handleCloseDialog} onSubscription={handleConfirmation} />
    </div>
  );
}

export default AddLicenseConfirm;
