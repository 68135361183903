/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-len */
import { Checkbox, Box, Button, DialogContentText, DialogContent, Dialog, DialogActions } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { UnpackNestedValue, useForm } from 'react-hook-form';
import moment from 'moment';
import { DEFAULT_PAGE, SIZE_PAGE } from '../../constants/app';
import { getGroups } from '../../state/actions/group';
import { start } from '../../lib/saga-promise';
import roles from '../../constants/roles';
import routes from '../../constants/routes';
import SearchCriteria from '../../component/Layout/SearchCriteria';
import SearchForm from '../../component/Layout/SearchForm';
import SearchResult from '../../component/Layout/SearchResult';
import GroupSelector from '../../component/Form/GroupSelector';
import IsInvalidCheckbox from '../../component/Form/IsInvalidCheckbox';
import { Column, columns, SearchCriteriaDefaultInput, SearchCriteriaInput } from './typesMailEndDate60Day';
import TGGrid from '../../component/Elements/TGGrid';
import IsIncludeExpiredRadio from '../../component/Form/IsIncludeExpiredRadio';
import { mailLicenseEndDate60day, sendMailLicensesEndDate60Day } from '../../state/actions/license';
import OverHideText from '../../component/Elements/OverHideText';
import TGTextField from '../../component/Elements/TGTextField';

const localStorageKey = 'searchLicenseMailEndDate60Day';

export default function MailLicenseEndDate60Day() {
  const { t, i18n } = useTranslation();
  const locate = i18n.language;
  const dispatch = useDispatch();

  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const handleRowCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, rowId: string) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, rowId]);
    } else {
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    }
  };
  const isAtLeastOneCheckboxChecked = selectedRows.length > 0;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sendMailLicenseEndDate60Day = async () => {
    handleClose();
    try {
      const extensionLicenses = {
        listLicense: selectedRows,
        Locate: locate,
      };
      await start(sendMailLicensesEndDate60Day, extensionLicenses, dispatch);
      handleSubmit(searchSubmit)();
      setSelectedRows([]);
    } catch (error: any) {
      // eslint-disable-next-line no-empty
    }
  };

  const { register, handleSubmit, setValue, reset, getValues, watch } = useForm<SearchCriteriaInput>({
    defaultValues: SearchCriteriaDefaultInput,
  });

  const rows = useSelector((state: RootStateOrAny) => state.license);
  const groups = useSelector((state: RootStateOrAny) => state.group.allGroups);
  const user = useSelector((state: RootStateOrAny) => state.auth.user);
  useEffect(() => {
    start(getGroups, { noLoading: true }, dispatch);
    setValueFromLocalStorage();
    handleSubmit(searchSubmit)();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = () => {
    setValue('pageIndex', DEFAULT_PAGE);
    handleSubmit(searchSubmit)();
  };

  const searchSubmit = async (data: UnpackNestedValue<SearchCriteriaInput>) => {
    await start(mailLicenseEndDate60day, data, dispatch);
    setItemLocalStorage({ ...data });
  };

  const setValueFromLocalStorage = () => {
    const searchLocalStorage = localStorage.getItem(localStorageKey);
    if (searchLocalStorage) {
      reset(JSON.parse(searchLocalStorage) as SearchCriteriaInput);
    }
  };

  const setItemLocalStorage = (params: SearchCriteriaInput) => {
    localStorage.setItem(localStorageKey, JSON.stringify(params));
  };

  const pageChange = (_: any, page: number) => {
    setValue('pageIndex', page);
    handleSubmit(searchSubmit)();
  };

  return (
    <SearchForm title={t('licenseMail.label.top')} description={t('descriptionSendMailEndDate60Day')}>
      <TGGrid sx={{ mb: 2, mt: 2 }} userRoleId={user.roleId} showRoles={[roles.systemAdmin, roles.groupAdmin]}>
        <SearchCriteria
          handleRefresh={() => {
            reset(SearchCriteriaDefaultInput);
            const searchLocalStorage = localStorage.getItem(localStorageKey);
            if (searchLocalStorage) {
              localStorage.removeItem(localStorageKey);
            }
          }}
          handleSearch={handleSearch}
        >
          <TGGrid item xs={12} sm={12} md={3} userRoleId={user.roleId} showRoles={[roles.systemAdmin]}>
            <GroupSelector
              groupId={watch('groupId')}
              handleChange={(data) => setValue('groupId', data?.groupId ?? null)}
              groups={groups}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={3}>
            <TGTextField registration={register('licenseCode')} label={t('common.label.licenseCode')} />
          </TGGrid>
          <TGGrid item xs={12} sm={3}>
            <IsIncludeExpiredRadio
              handleChange={(e, value) => setValue('isIncludeExpired', value === 'true')}
              value={watch('isIncludeExpired')}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={3}>
            <IsInvalidCheckbox registration={register('isInvalid')} checked={watch('isInvalid')} />
          </TGGrid>
        </SearchCriteria>
      </TGGrid>
      <TGGrid>
        <SearchResult<Column>
          totalCount={rows.total}
          page={getValues('pageIndex')}
          columns={columns}
          rows={rows?.licenses}
          handlePageChange={pageChange}
          tableCell={(row: any, rowKey: number, column: Column): ReactNode => {
            const value = row[column.id];
            switch (column.id) {
              case 'licenseId':
                return rowKey + 1 + (getValues('pageIndex') - 1) * SIZE_PAGE;
              case 'licenseCode':
                return (
                  <Link to={routes.licenseEdit.replace(':id', row.licenseId)}>
                    <OverHideText>{value}</OverHideText>
                  </Link>
                );
              case 'availableDeviceNumber':
                return <Link to={`${routes.licenseDevice}?licenseCode=${row.licenseCode}`}>{value}</Link>;
              case 'groupNameWithCode':
                return `${row.groupName} < ${row.groupCode} > `;
              case 'isInvalid':
                return value ? <LockOutlinedIcon color="error" /> : '';
              case 'licenseStatus':
                if (row.purchaseType === 'Expired') {
                  return (t('licenseStatusExpired'));
                }
                return (row.purchaseType === 'Trial') ? t('licenseStatusFreeTrial') : t('licenseStatusContracted');
              case 'renewFlag':
                if (row.purchaseType === 'Trial') {
                  return (t('common.label.noContract'));
                }
                if (row.renewFlag === false || row.isInvalid || row.purchaseType === 'Expired') {
                  return (t('licenseStatusContractedStop'));
                }
                return (t('common.label.renewFlag'));
              case 'createdAt':
                return moment.utc(row.createdAt).format('YYYY/MM/DD');
              case 'endDate':
                if (row.purchaseType === 'Expired') {
                  if (moment.utc(row.endDate).isBefore(moment())) {
                    return moment.utc(row.endDate).format('YYYY/MM/DD');
                  }
                  if (moment.utc(row.endDateTrail).isBefore(moment())) {
                    return moment.utc(row.endDateTrail).format('YYYY/MM/DD');
                  }
                }
                return (row.purchaseType === 'Trial') ? moment.utc(row.endDateTrail).format('YYYY/MM/DD') : moment.utc(row.endDate).format('YYYY/MM/DD');
              case 'mailLicense':
                return (
                  <Checkbox
                    checked={selectedRows.includes(row.licenseId)}
                    onChange={(event) => handleRowCheckboxChange(event, row.licenseId)}
                    color="primary"
                  />
                );
              default:
                return column.format ? column.format(value) : <OverHideText>{value}</OverHideText>;
            }
          }}
        />
      </TGGrid>
      <TGGrid container mt={5} mb={3} display="flex" justifyContent="right">
        <TGGrid item md={8} sm={11}>
          <Box display="flex" alignItems="center" justifyContent="right">
            <Button
              variant="contained"
              sx={{ marginLeft: '20px', width: 'auto', fontSize: '14px' }}
              onClick={handleClickOpen}
              disabled={!isAtLeastOneCheckboxChecked}
            >
              {t('mailLicenseBtn')}
            </Button>
          </Box>
        </TGGrid>
      </TGGrid>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>{t('sendMailEndDate60Day')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" autoFocus>{t('btnNo')}</Button>
          <Button onClick={sendMailLicenseEndDate60Day} variant="contained" autoFocus>{t('btnYes')}</Button>
        </DialogActions>
      </Dialog>
    </SearchForm>
  );
}
